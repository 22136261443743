import React, { useState } from "react";
import classes from "./pseudonymizer.module.css";
import {
  setPUserPseudonymizer,
  selectEnvironmentPseudonymizer,
  selectRegiontPseudonymizer,
  selectAudiencePseudonymizer,
  selectPUserPseudonymizer,
  getUserFromPseudoUser,
} from "./pseudonymizerSlice";
import { useDispatch, useSelector } from "react-redux";

function PseudoUserToUser() {
  // eslint-disable-next-line no-unused-vars
  const [resultText, setResultText] = useState("Result:");
  const [invalidPUSERText, setInvalidPUSERText] = useState(false);

  const dispatch = useDispatch();

  const pUser = useSelector(selectPUserPseudonymizer);

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);
  const audience = useSelector(selectAudiencePseudonymizer);

  const userFromPseudoUserHandler = (
    dispatch,
    environment,
    region,
    vin,
    pVin,
    mbbUserId,
    pUser,
    audience
  ) => {
    dispatch(
      getUserFromPseudoUser({
        environment,
        region,
        vin,
        pVin,
        mbbUserId,
        pUser,
        audience,
      })
    );
  };

  return (
    <>
      <div className={classes.pseudoVINInputSection}>
        <div
          className={classes.pseudoVINInputSectionText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          {" "}
          Pseudo User:{" "}
        </div>
        <div className={classes.pseudoVinSearchContainer}>
          <input
            id="searchPUser"
            autoComplete="off"
            required
            data-cy="searchInput"
            value={pUser || ""}
            onChange={(event) => {
              dispatch(setPUserPseudonymizer(event.target.value));
              setInvalidPUSERText(false);
            }}
            style={{ userSelect: "text", cursor: "auto" }}
            readOnly={false}
          ></input>
          <label
            htmlFor="searchPUser"
            className={classes.searchLabel}
            data-cy="searchLabel"
            style={{ userSelect: "text", cursor: "auto" }}
          ></label>
        </div>
        <div
          className={classes.actionBtnSearchForPseudoVin}
          onClick={() => {
            if (!pUser) {
              setInvalidPUSERText(true);
            } else {
              setResultText("Result:");
              userFromPseudoUserHandler(
                dispatch,
                envPseudonymizer,
                regPseudonymizer,
                "",
                "",
                "",
                pUser,
                audience
              );
              setInvalidPUSERText(false);
              setResultText("MBB User for Pseudo User:");
            }
          }}
        >
          Get User
        </div>
      </div>
      <div>
        {" "}
        {invalidPUSERText ? (
          <div className={classes.invalidValueWarning}>
            Invalid input value for Pseudo User
          </div>
        ) : (
          <div className={classes.invalidValueWarning}></div>
        )}
      </div>
    </>
  );
}

export default PseudoUserToUser;
