import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  vehicleJobs: [],
  isLoadingDataActivationJobs: false,
};

// Slice
const activationJobsSlice = createSlice({
  name: "activationJobs",
  initialState,
  reducers: {
    setVehicleJobs: (state, action) => {
      state.vehicleJobs = action.payload;
    },

    setIsLoadingDataActivationJobs: (state, action) => {
      state.isLoadingDataActivationJobs = action.payload;
    },
  },
});

// Selectors

export const selectVehicleJobs = (state) => state.activationJobs.vehicleJobs;

export const selectIsLoadingDataActivationJobs = (state) =>
  state.activationJobs.isLoadingDataActivationJobs;
// Actions

export const { setVehicleJobs, setIsLoadingDataActivationJobs } =
  activationJobsSlice.actions;

//Getter

export const getVehicleJobs = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataActivationJobs(true));
  postWithAuth("GETACTIVATIONJOBSBYVEHICLEID", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setVehicleJobs(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataActivationJobs(false));
    });
};

export default activationJobsSlice.reducer;
