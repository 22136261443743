import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  allVehicleServiceClaims: {},
  isLoadingDataAllVehicleServiceClaims: [],
};

// Slice
const allVehicleServiceClaimsSlice = createSlice({
  name: "allVehicleServiceClaims",
  initialState,
  reducers: {
    setAllVehicleServiceClaims: (state, action) => {
      state.allVehicleServiceClaims = action.payload;
    },

    setIsLoadingDataAllVehicleServiceClaims: (state, action) => {
      state.isLoadingDataAllVehicleServiceClaims = action.payload;
    },
  },
});

// Selectors

export const selectAllVehicleServiceClaims = (state) =>
  state.allVehicleServiceClaims.allVehicleServiceClaims;

export const selectIsLoadingDataAllVehicleServiceClaims = (state) =>
  state.allVehicleServiceClaims.isLoadingDataAllVehicleServiceClaims;
// Actions

export const {
  setAllVehicleServiceClaims,

  setIsLoadingDataAllVehicleServiceClaims,
} = allVehicleServiceClaimsSlice.actions;

//Getter

export const getAllVehicleServiceClaims =
  (environment, region, vin) => (dispatch) => {
    dispatch(setIsLoadingDataAllVehicleServiceClaims(true));
    postWithAuth("GETALLVEHICLESERVICECLAIMS", {}, { environment, region, vin })
      .then((response) => {
        if (response !== null) {
          dispatch(setAllVehicleServiceClaims(response));
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        logError(error);
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setIsLoadingDataAllVehicleServiceClaims(false));
      });
  };

export default allVehicleServiceClaimsSlice.reducer;
