import React, { useState } from "react";
import classes from "./pseudonymizer.module.css";
import copy from "../../ui/Assets/Icons/copyToClipboard.png";
import CopyToClipboard from "react-copy-to-clipboard";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import {
  selectIsLoadingDataPseudonymizer,
  selectResultContent,
  selectErrorDisplay,
} from "./pseudonymizerSlice";
import { useSelector } from "react-redux";

function SingleResult() {
  // eslint-disable-next-line no-unused-vars
  const [resultText, setResultText] = useState("Result:");

  const isLoadingDataPseudonymizer = useSelector(
    selectIsLoadingDataPseudonymizer
  );
  const showErrorDisplay = useSelector(selectErrorDisplay);
  const resultContent = useSelector(selectResultContent);

  return (
    <div className={classes.displayedResultSection}>
      {isLoadingDataPseudonymizer ? (
        <LoadingSpinner />
      ) : (
        <div className={classes.displayedResult}>
          <div className={classes.displayedResultText}>{resultText}</div>

          <div className={classes.resultAvailabilityBox}>
            <div
              className={classes.resultAvailable}
              style={{ userSelect: "text", cursor: "auto" }}
            >
              {showErrorDisplay
                ? `Internal Server Error. Try again later`
                : resultContent}
              {/* {resultContent} */}
            </div>

            <div className={classes.copyVIN}>
              <CopyToClipboard text={`${resultContent}`}>
                <img src={copy} alt="" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleResult;
