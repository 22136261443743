import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";
import { setUuidByVIN } from "#pages/TOOL-vinByUuID/vinByUuIDSlice.js";

const initialState = {
  vehicleUuID: {},
  isLoadingDataVehicleUuID: false,
  emptyStateUuid: true,
};

// Slice
const vehicleUuIDSlice = createSlice({
  name: "vehicleUuID",
  initialState,
  reducers: {
    setVehicleUuID: (state, action) => {
      state.vehicleUuID = action.payload;
    },
    setIsLoadingDataVehicleUuID: (state, action) => {
      state.isLoadingDataVehicleUuID = action.payload;
    },
    setEmptyStateUuid: (state, action) => {
      state.emptyStateUuid = action.payload;
    },
  },
});

// Selectors

export const selectVehicleUuID = (state) => state.vehicleUuID.vehicleUuID;
export const selectIsLoadingDataVehicleUuID = (state) =>
  state.vehicleUuID.isLoadingDataVehicleUuID;
export const selectEmptyStateUuid = (state) => state.vehicleUuID.emptyStateUuid;

// Actions

export const {
  setVehicleUuID,
  setIsLoadingDataVehicleUuID,
  setEmptyStateUuid,
} = vehicleUuIDSlice.actions;

//Getter

export const getVehicleByUuid = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataVehicleUuID(true));
  dispatch(setEmptyStateUuid(false));
  postWithAuth("GETVEHICLEBYUUID", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setVehicleUuID(response));
        dispatch(setUuidByVIN(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataVehicleUuID(false));
    });
};

export default vehicleUuIDSlice.reducer;
