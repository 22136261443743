import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    dynamicURL: "",
}

// Slice
const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setDynamicURL: (state, action) => {
            state.dynamicURL = action.payload
        },
    }
})

// Selectors
export const selectDynamicUrl = (state) => state.app.dynamicURL;


// Actions

export const {
    setDynamicURL
} = appSlice.actions;


export default appSlice.reducer;
