import { useSelector } from "react-redux";
import { selectVinCheckArray } from "./vdsCheckSlice";
import classes from "./backendDataInfoPage.module.css";

function VdsCheckTableCustom() {
  const vdsCheckArray = useSelector(selectVinCheckArray);

  return (
    <div className={classes.vdsTableContainer}>
      <div className={classes.vdsTable}>
        <div className={classes.vdsTableHeadContainer}>
          <div className={classes.vdsTableHeadCellICTO}>ICTO</div>

          <div className={classes.vdsTableHeadCellRegion}>Region</div>
          <div className={classes.vdsTableHeadCellEnvironment}>Environment</div>
          <div className={classes.vdsTableHeadCellCheck}>VIN Check</div>
          <div className={classes.vdsTableHeadCellRegistered}>Registered</div>

          <div className={classes.vdsTableHeadCellURL}>Base-URL</div>
        </div>
        <div className={classes.vdsTableBodyContainer}>
          {vdsCheckArray?.map((row, index) => (
            <div
              className={classes.vdsTableBodyRow}
              style={{ userSelect: "text", cursor: "auto" }}
              key={`${row.Environment}-${index}`}
            >
              <div
                className={classes.vdsTableBodyCellICTO}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {row.systemId && row.VehcileExists ? row.systemId : "-"}
              </div>
              <div
                className={classes.vdsTableBodyCellRegion}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {row.Region.toUpperCase()}
              </div>
              <div
                className={classes.vdsTableBodyCellEnvironment}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {row.Environment.toUpperCase()}
              </div>

              <div
                className={
                  row.VehcileExists
                    ? classes.vdsTableBodyCellAvailable
                    : classes.vdsTableBodyCellUnavailable
                }
              >
                {row.VehcileExists ? "known" : "unknown"}
              </div>
              <div
                className={classes.vdsTableBodyCellRegistered}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {`${"not available"}`}
              </div>
              <div
                className={classes.vdsTableBodyCellUrl}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {row.url && row.VehcileExists ? row.url : "-"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VdsCheckTableCustom;
