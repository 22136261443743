import { createSlice} from "@reduxjs/toolkit";

const initialState = {
    infoSnackBar: {
        open: false,
    },
  
}

// Slice
const infoSnackBarSlice = createSlice({
    name: "infoSnackBar",
    initialState,
    reducers: {
        setInfoSnackBar: (state, action) => {
            state.infoSnackBar.open = action.payload;
           
        },
    }
})

// Selectors
export const selectOpenInfoSnackBar = (state) => state.infoSnackBar.infoSnackBar.open;

// Actions
export const {
    setInfoSnackBar,
    
} = infoSnackBarSlice.actions;


export default infoSnackBarSlice.reducer;
