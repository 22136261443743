import React, { useEffect, useState } from "react";
import "react-international-phone/style.css";
import classes from "./CcpGenerator.module.css";
import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import ManualInput from "./ManualInput.jsx";
import UploadCSV from "./UploadCSV.jsx";

function CcpGenerator() {
  const [selectedTab, setSelectedTab] = useState("upload");

  useEffect(() => {
    setSelectedTab("upload");
  }, []);
  return (
    <div className={classes.ccpGeneratorPageContainer}>
      <div className={classes.ccpGeneratorContents}>
        <div className={classes.ccpGeneratorContentsTitle}>
          CCP File Generator
        </div>
        <HorizontalDevider />
        <div className={classes.ccpGeneratorTabsSection}>
          <div
            className={
              selectedTab === "upload"
                ? classes.ccpGeneratorTabSelected
                : classes.ccpGeneratorTab
            }
            onClick={() => {
              setSelectedTab("upload");
            }}
          >
            Upload CSV
          </div>
          <div
            className={
              selectedTab === "manual"
                ? classes.ccpGeneratorTabSelected
                : classes.ccpGeneratorTab
            }
            onClick={() => {
              setSelectedTab("manual");
            }}
          >
            Manual Input
          </div>
        </div>
        {/* Tab components containers */}
        {selectedTab === "upload" ? <UploadCSV /> : <ManualInput />}
      </div>
    </div>
  );
}

export default CcpGenerator;
