import React, { useEffect } from "react";
import welcome from "../../ui/Assets/Vehicle Images/welcomeLowRes.jpg";
import FullButton from "../../ui/Buttons/Full Button/FullButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjects,
  selectAllProjects,
  selectIsFirstVisit,
  setIsFirstVisit,
} from "../Homepage/projectSlice";
import { getTests, selectAllTests } from "../TestData Selection/testsSlice";
import { Typography } from "@mui/material";
import Themesong from "#components/themesong/Themesong.jsx";

function WelcomePage() {
  const dispatch = useDispatch();
  const isFirstVisit = useSelector(selectIsFirstVisit);

  useEffect(() => {
    const hasBeenVisitedBefore = sessionStorage.getItem("hasBeenVisited");
    if (!hasBeenVisitedBefore) {
      sessionStorage.setItem("hasBeenVisited", "true");
      dispatch(setIsFirstVisit(true));
      window.location.reload();
      dispatch(getProjects());
      dispatch(getTests());
    } else {
      console.log(window.location.href);
      console.log(isFirstVisit);
      dispatch(getProjects());
      dispatch(getTests());
    }
  }, [dispatch, isFirstVisit]);

  const projectsNumber = useSelector(selectAllProjects);
  const scenarios = useSelector(selectAllTests);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
          <img
            src={welcome}
            alt=""
            style={{
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minHeight: "100%",
              minWidth: "100%",
              maxWidth: "none",
            }}
          />
        </div>
        <div
          style={{
            zIndex: "1000",
            textAlign: "center",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
              height: "1.5rem",
            }}
          >
            <div style={{ position: "absolute", left: "50%", top: "1.5rem" }}>
              <Themesong theme={"light"} />
            </div>
          </div>
          <div
            style={{
              height: "10rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "5%",
                lineHeight: "5rem",
                fontSize: "3.5rem",
                color: "var(--light-gray",
                opacity: "0.85",
                fontFamily: "var(--thin",
              }}
            >
              Welcome to DPSS Test platform
            </div>
          </div>
          <div
            style={{
              height: "10rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "17.5%",
                lineHeight: "5rem",
                fontSize: "2.5rem",
                color: "var(--light-gray)",
                opacity: "0.9",
                fontFamily: "var(--regular",
                textShadow: "1.5px 1.5px 2.5px var(--secondary",
              }}
            >
              Test {projectsNumber.length} vehicle projects with{" "}
              {scenarios.length} different scenarios
            </div>
          </div>
          <div
            style={{
              height: "10rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ position: "absolute", bottom: "7.5%" }}>
              <FullButton
                buttonText={"Proceed to selection"}
                destination={"/homepage"}
                disabledConditions={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0.75rem",
          width: "75%",
          left: "12.5%",
          textAlign: "center",
          color: "var(--light-gray)",
        }}
      >
        <Typography fontFamily={"var(--thin)"} fontSize={"0.75rem"}>
          This code is protected by intellectual property rights. Dr. Ing. h.c.
          F. Porsche AG owns exclusive rights of use. © 2024, Dr. Ing. h.c. F.
          Porsche AG.
        </Typography>
      </div>
    </>
  );
}

export default WelcomePage;
