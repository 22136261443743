import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TestItem from "../../components/Test List/Test Item/TestItem";
import {
  selectAllTests,
  selectSelectedTest,
  setSelectedTest,
  selectShowModal,
} from "../../pages/TestData Selection/testsSlice";
import classes from "./TestList.module.css";
import ModalCustom from "../../ui/Modal/ModalCustom";

function TestList({ loadTest }) {
  const dispatch = useDispatch();
  const allTests = useSelector(selectAllTests);
  const selectedTest = useSelector(selectSelectedTest);
  const modal = useSelector(selectShowModal);

  return (
    <div className={classes.gridContainer} data-cy="tests-container">
      <div className={classes.flexContainerHeader}>
        <div className={classes.flexItemHeader}>Available tests</div>
        <div className={classes.flexContainerRow}>
          {allTests.map((test) => (
            <TestItem
              data-cy="test-item"
              loadTest={() => loadTest(test.scenarioType)}
              key={test.scenarioType}
              selected={selectedTest.scenarioType === test.scenarioType}
              setSelected={() => {
                dispatch(setSelectedTest(test.scenarioType));
                console.log(test.scenarioType);
                console.log(selectedTest.scenarioType);
              }}
              title={test.scenarioTitle}
              description={test.scenarioDescription}
              scenarioType={test.scenarioType}
            />
          ))}
        </div>
        {modal && <ModalCustom />}
      </div>
    </div>
  );
}

export default TestList;
