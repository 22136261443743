import React, { useLayoutEffect } from "react";
import { setDynamicURL } from "../../appSlice";

function DummyPageToGetHref() {
  useLayoutEffect(() => {
    console.log(window.location.href);
    setDynamicURL(window.location.href);
  }, []);
  return (
    <div
      style={{
        opacity: "0",
        height: "0",
        width: "0",
        backgroundColor: "#d9d9d9",
      }}
    ></div>
  );
}

export default DummyPageToGetHref;
