import axios from "axios";
import { pca } from "../utils/authConfig";
import { config, configBlob } from "./api-config";
import { PATHS, PATHS_local } from "./api-endpoints";

const env = window.location.href;
const currentPaths = env.includes("localhost") ? PATHS_local : PATHS;

export const getAccessToken = async () => {
  const bearer = sessionStorage.getItem("AzureADBearer");
  if (bearer) return bearer;
  let account = pca.getActiveAccount();
  if (!account) {
    account = pca.getAllAccounts()[0];
  }

  if (account) {
    return pca
      .acquireTokenSilent({
        scopes: [
          "api://" +
            process.env.REACT_APP_API_CLIENT_ID +
            "/user_impersonation",
        ],
        account: account,
      })
      .then((response) => {
        if (response) {
          return response.accessToken;
        }
      });
  }
};

const createHeaders = async () => ({
  Authorization: "Bearer " + (await getAccessToken()),
  accept: "text/plain",
});

export async function get(key) {
  axios.get(currentPaths[key], config).then((res) => res.data);
}

export async function getWithAuth(routeParam, parameters, id1 = null) {
  const reqConfig = config;
  let routes =
    id1 != null
      ? [routeParam].replace("idToReplace1", id1)
      : currentPaths[routeParam];
  reqConfig.params = parameters;
  return axios
    .get(routes, {
      ...reqConfig,
      headers: await createHeaders(),
    })
    .then((res) => res.data);
}

export async function post(url, payload) {
  axios.post(url, payload, config).then((res) => res.data);
}

export async function postWithAuth(routeParam, parameters, body, id1 = null) {
  const reqConfig = config;
  reqConfig.params = parameters;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  let routes =
    id1 != null
      ? currentPaths[routeParam].replace("idToReplace1", id1)
      : currentPaths[routeParam];
  return axios
    .post(routes, body, {
      ...reqConfig,
      headers: await createHeaders(),
    })
    .then((res) => res.data);
}

export async function getXMLWithAuth(routeParam, parameters, body, id1 = null) {
  const reqConfig = { ...configBlob }; // Clone configBlob to avoid mutation
  let routes =
    id1 != null
      ? currentPaths[routeParam].replace("idToReplace1", id1)
      : currentPaths[routeParam];
  reqConfig.responseType = "blob";
  reqConfig.params = parameters;

  if (body instanceof FormData) {
    reqConfig.headers = await createHeaders();
    reqConfig.headers["Content-Type"] = "multipart/form-data"; // Set Content-Type
  }

  return axios
    .post(routes, body, reqConfig) // Send body directly, not wrapped in an object
    .then((res) => res.data);
}

export async function postWithAuthForZipFile(
  routeParam,
  parameters,
  body,
  id1 = null
) {
  const reqConfig = {
    ...config,
    params: parameters,
    responseType: "blob", // Set response type to 'blob'
  };
  axios.defaults.headers.post["Content-Type"] = "application/json";
  let routes =
    id1 != null
      ? currentPaths[routeParam].replace("idToReplace1", id1)
      : currentPaths[routeParam];
  return axios
    .post(routes, body, {
      ...reqConfig,
      headers: await createHeaders(),
    })
    .then((res) => res.data); // The response is now a Blob
}
