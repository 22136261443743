import React from "react";
import classes from "./SearchBoxRelease.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilterRelease,
  setFilterRelease,
} from "../../pages/Homepage/projectSlice";

function SearchBoxRelease() {
  const dispatch = useDispatch();
  const filterRelease = useSelector(selectFilterRelease);

  const setFilters = (event) => {
    dispatch(setFilterRelease(event.target.value));
  };

  return (
    <div className={classes.searchElementRelease} data-cy='searchElement'>
      <div className={classes.searchControlRelease} data-cy='searchControl'>
        <div className={classes.searchBoxRelease} data-cy='searchBox'>
          <input
            id='searchDetailsRelease'
            autoComplete='off'
            required
            data-cy='searchInput'
            value={filterRelease}
            onChange={setFilters}
          ></input>
          <label
            htmlFor='searchDetailsRelease'
            className={classes.searchLabel}
            data-cy='searchLabel'
          ></label>
        </div>
      </div>
    </div>
  );
}

export default SearchBoxRelease;
