import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playMusic: false,
};

// Slice
const themesongSlice = createSlice({
  name: "themesong",
  initialState,
  reducers: {
    setPlayMusic: (state, action) => {
      state.playMusic = action.payload;
    },
  },
});

// Selectors

export const selectPlayMusic = (state) => state.themesong.playMusic;

export const { setPlayMusic } = themesongSlice.actions;

export default themesongSlice.reducer;
