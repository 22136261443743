import React from "react";
import classes from "./NavigationSection.module.css";
import logo from "../../ui/Assets/Icons/logo-with-copyright.png";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "primeicons/primeicons.css";
import { setVehicleJobs } from "../../pages/backendDataInfoPage/activationJobsSlice";
import { setServiceGroup } from "../../pages/backendDataInfoPage/serviceGroupSlice";
import { setVehicleClaims } from "../../pages/backendDataInfoPage/vehicleClaimsSlice";
import { setVehicleUuID } from "../../pages/backendDataInfoPage/vehicleUuIDSlice";
import { setVehicleUsers } from "../../pages/backendDataInfoPage/vehicleUsersSlice";
import Themesong from "#components/themesong/Themesong.jsx";
function NavigationSection() {
  const dispatch = useDispatch();
  const history = useHistory();

  const clearBackendDataHandler = () => {
    dispatch(setVehicleUuID({}));
    dispatch(setVehicleUsers({}));
    dispatch(setServiceGroup({}));
    dispatch(setVehicleJobs({}));
    dispatch(setVehicleClaims({}));
  };

  return (
    <div className={classes.navSection}>
      <div className={classes.logo}>
        <img
          src={logo}
          alt=""
          style={{ width: "128px" }}
          onClick={() => history.push("/")}
        />
      </div>
      <div
        style={{
          width: "100%",
          position: "relative",
          height: "1.5rem",
        }}
      >
        <div style={{ position: "absolute", left: "50%", top: "-2rem" }}>
          <Themesong theme={"dark"} />
        </div>
      </div>
      <div className={classes.currentPath}>
        <i className="pi pi-home" style={{ fontSize: "1.5rem" }}></i>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? `${classes.activeLink}` : `${classes.nonActiveLink}`
          }
          exact={true}
          onClick={clearBackendDataHandler}
        >
          Home
        </NavLink>
        <div style={{ width: "2rem" }}></div>
        <i className="pi pi-user" style={{ fontSize: "1.5rem" }}></i>
        <NavLink
          to="/homepage"
          className={({ isActive }) =>
            isActive ? `${classes.activeLink}` : `${classes.nonActiveLink}`
          }
          exact={true}
        >
          Account
        </NavLink>
      </div>
    </div>
  );
}

export default NavigationSection;
