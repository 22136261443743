import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

const RedirectPage = () => {
    const history = useHistory();

    useLayoutEffect(() => {
        const shouldRedirect = true;

        if (shouldRedirect) {
            const redirectTimeout = setTimeout(() => {
                history.push('/homepage');
            }, 3500);

            return () => clearTimeout(redirectTimeout);
        }
    }, [history]);


    return (
        <div style={{}}>
            <div style={{ fontFamily: "var(--regular)", display: "flex", justifyContent: "center", padding: "1rem", color: "var(--primary)", fontSize: "20px", border: "2px solid var(--primary)", textAlign: "center" }}>Page reloads reset user selection and interfere with database connection. You will be redirected to homepage to select test data.   </div>
        </div>
    );
};

export default RedirectPage;