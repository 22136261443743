import React, { useEffect } from "react";
import classes from "./backendDataInfoPage.module.css";
import { useSelector } from "react-redux";
import offline from "../../ui/Assets/Icons/stop.svg";
import online from "../../ui/Assets/Icons/online.png";
import pause from "../../ui/Assets/Icons/wait.png";
import noDevice from "../../ui/Assets/Icons/blocked.png";
import empty from "../../ui/Assets/Icons/empty.png";
import { selectEmptyFields } from "./backendInfoSlice";
import {
  selectAccessForbiddenToLiveStatus,
  selectPingDataLive,
  selectPingTimestampLive,
  selectNoDeviceFound,
} from "./connectionStatusLiveSlice";

// import { timeouts } from "./BackendDataInfoPage";

function VehicleStatusTableLive() {
  const pingDataLive = useSelector(selectPingDataLive);
  const pingTimestampLive = useSelector(selectPingTimestampLive);
  const emptyFields = useSelector(selectEmptyFields);
  const noDeviceFound = useSelector(selectNoDeviceFound);

  function getIconForStatus(status) {
    switch (status) {
      case true:
        return online;
      case false:
        return offline;
      default:
        return noDevice;
    }
  }

  function isValidISODate(dateString) {
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
  }

  function formatDateIfValid(dateString) {
    const dateObject = new Date(dateString);
    if (!isNaN(dateObject.getTime())) {
      return dateObject.toISOString().replace(/[TZ]/g, " ").slice(0, -4);
    } else {
      return "Invalid date";
    }
  }

  const accessForbidden = useSelector(selectAccessForbiddenToLiveStatus);

  useEffect(() => {
    console.log(pingDataLive);
  }, [accessForbidden, pingDataLive]);

  return (
    // <div className={classes.flexContainerRowTable} style={{ margin: "0 1rem" }}>
    <div className={classes.tableDivCONNECTION}>
      <div className={classes.tableTitle}>
        <div
          className={classes.tableTitleText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          Connection Status (Live)
        </div>
      </div>
      {/*  */}
      {/* If there is any issue, just show the user an informational message */}
      {/*  */}
      {accessForbidden ? (
        <div className={classes.tableRowError}>
          Access forbidden. No access to resource.
        </div>
      ) : (
        <div className={classes.tableRow}>
          <div
            className={classes.tableRowTextStatus}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            Ping 1:{" "}
          </div>
          <div className={classes.tableRowStatus}>
            <img
              src={
                emptyFields
                  ? empty
                  : pingDataLive[0] === undefined
                  ? pause
                  : getIconForStatus(pingDataLive[0]?.Devices[0]?.Connected)
              }
              alt=""
            ></img>
          </div>
          <div
            className={classes.tableRowStatusName}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {emptyFields && pingDataLive[0]?.Devices[0] === undefined
              ? "No data..."
              : pingDataLive[0] === undefined
              ? (() => {
                  return "Wait...";
                })()
              : pingDataLive[0]?.Devices[0] === undefined && noDeviceFound
              ? "No device found"
              : pingDataLive[0]?.Devices[0]?.Connected
              ? "Connected"
              : "Not Connected"}
          </div>
          <div className={classes.tableRowStatusTimestamp}>
            Timestamp:&nbsp;
            <div
              style={{
                marginRight: "0.1rem",
                userSelect: "text",
                cursor: "auto",
                width: "150%",
                display: "flex",
                fontFamily: "var(--regular)",
                lineHeight: "1.5rem",
                fontSize: "0.8rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {pingTimestampLive[0] === "" ? "Wait..." : pingTimestampLive[0]}
            </div>{" "}
          </div>

          <div
            style={{
              marginRight: "0.1rem",
              userSelect: "text",
              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Start:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[0]?.Devices[0]?.ConnectionStarted === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[0]?.Devices[0]?.ConnectionStarted)
              ? formatDateIfValid(
                  pingDataLive[0]?.Devices[0]?.ConnectionStarted
                )
              : ""}
          </div>
          <div
            style={{
              userSelect: "text",
              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Stop:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[0]?.Devices[0]?.ConnectionStopped === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[0]?.Devices[0]?.ConnectionStopped)
              ? formatDateIfValid(
                  pingDataLive[0]?.Devices[0]?.ConnectionStopped
                )
              : ""}
          </div>
        </div>
      )}

      {accessForbidden ? (
        <div className={classes.tableRowError}>
          Access forbidden. No access to resource.
        </div>
      ) : (
        <div className={classes.tableRow}>
          <div
            className={classes.tableRowTextStatus}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            Ping 2:{" "}
          </div>
          <div className={classes.tableRowStatus}>
            <img
              src={
                emptyFields
                  ? empty
                  : pingDataLive[1] === undefined
                  ? pause
                  : getIconForStatus(pingDataLive[1]?.Devices[0]?.Connected)
              }
              alt=""
            ></img>
          </div>
          <div
            className={classes.tableRowStatusName}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {emptyFields && pingDataLive[1]?.Devices[0] === undefined
              ? "No data..."
              : pingDataLive[1] === undefined
              ? (() => {
                  return "Wait...";
                })()
              : pingDataLive[1]?.Devices[0] === undefined && noDeviceFound
              ? "No device found"
              : pingDataLive[1]?.Devices[0]?.Connected
              ? "Connected"
              : "Not Connected"}
          </div>

          <div className={classes.tableRowStatusTimestamp}>
            Timestamp:&nbsp;
            <div
              style={{
                marginRight: "0.1rem",
                userSelect: "text",
                cursor: "auto",
                width: "150%",
                display: "flex",
                fontFamily: "var(--regular)",
                lineHeight: "1.5rem",
                fontSize: "0.8rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {pingTimestampLive[1] === "" ? "Wait..." : pingTimestampLive[1]}
            </div>{" "}
          </div>
          <div
            style={{
              userSelect: "text",
              marginRight: "0.1rem",

              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Start:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[1]?.Devices[0]?.ConnectionStarted === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[1]?.Devices[0]?.ConnectionStarted)
              ? formatDateIfValid(
                  pingDataLive[1]?.Devices[0]?.ConnectionStarted
                )
              : ""}
          </div>
          <div
            style={{
              userSelect: "text",
              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Stop:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[1]?.Devices[0]?.ConnectionStopped === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[1]?.Devices[0]?.ConnectionStopped)
              ? formatDateIfValid(
                  pingDataLive[1]?.Devices[0]?.ConnectionStopped
                )
              : ""}
          </div>
        </div>
      )}
      {accessForbidden ? (
        <div className={classes.tableRowError}>
          Access forbidden. No access to resource.
        </div>
      ) : (
        <div className={classes.tableRow}>
          <div
            className={classes.tableRowTextStatus}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            Ping 3:{" "}
          </div>
          <div className={classes.tableRowStatus}>
            <img
              src={
                emptyFields
                  ? empty
                  : pingDataLive[2] === undefined
                  ? pause
                  : getIconForStatus(pingDataLive[2]?.Devices[0]?.Connected)
              }
              alt=""
            ></img>
          </div>
          <div
            className={classes.tableRowStatusName}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {emptyFields && pingDataLive[2]?.Devices[0] === undefined
              ? "No data..."
              : pingDataLive[2] === undefined
              ? (() => {
                  return "Wait...";
                })()
              : pingDataLive[2]?.Devices[0] === undefined && noDeviceFound
              ? "No device found"
              : pingDataLive[2]?.Devices[0]?.Connected
              ? "Connected"
              : "Not Connected"}
          </div>
          <div className={classes.tableRowStatusTimestamp}>
            Timestamp:&nbsp;
            <div
              style={{
                marginRight: "0.1rem",
                userSelect: "text",
                cursor: "auto",
                width: "150%",
                display: "flex",
                fontFamily: "var(--regular)",
                lineHeight: "1.5rem",
                fontSize: "0.8rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {pingTimestampLive[2] === "" ? "Wait..." : pingTimestampLive[2]}
            </div>{" "}
          </div>
          <div
            style={{
              userSelect: "text",
              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Start:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[2]?.Devices[0]?.ConnectionStarted === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[2]?.Devices[0]?.ConnectionStarted)
              ? formatDateIfValid(
                  pingDataLive[2]?.Devices[0]?.ConnectionStarted
                )
              : ""}
          </div>
          <div
            style={{
              userSelect: "text",
              cursor: "auto",
              width: "150%",
              display: "flex",
              fontFamily: "var(--regular)",
              lineHeight: "1.5rem",
              fontSize: "0.8rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontFamily: "var(--semiBold)", fontSize: "1rem" }}>
              {" "}
              Stop:&nbsp;{" "}
            </div>{" "}
            {pingDataLive[2]?.Devices[0]?.ConnectionStopped === ""
              ? "Wait..."
              : isValidISODate(pingDataLive[2]?.Devices[0]?.ConnectionStopped)
              ? formatDateIfValid(
                  pingDataLive[2]?.Devices[0]?.ConnectionStopped
                )
              : ""}
          </div>
        </div>
      )}
    </div>
    // </div>
  );
}

export default VehicleStatusTableLive;
