import React from 'react';
import classes from "./Snackbar.module.css"
import close from "../../ui/Assets/Icons/close.png"
import { useSelector } from 'react-redux';
import { selectOpen, selectSnackBar, setOpen } from './snackbarSlice';
import { useDispatch } from 'react-redux';

function Snackbar() {
   const dispatch = useDispatch();
    const snackbar = useSelector(selectSnackBar)
    const open = useSelector(selectOpen);
    const code = snackbar.errorCode;
    const message = snackbar.errorMessage;


  return (
    <div className={classes.snackBarContainer}>
    { open ?   <div className={classes.snackBarContents}>
          <div className={classes.closeImg} onClick={() => dispatch(setOpen(false))}><img src={close} alt="" /></div>
        <div className={classes.body}>
          <div className={classes.snackBarTitle}>
            <p>
            Error Code: 
            </p>
            <span>{code}</span>
          </div>
            <div className={classes.snackBarMessage}>
                <p>
                Error Message:
                </p>
                <span>{message}</span>
            </div>
          </div>
        </div> : ""}
    </div>
  )
}

export default Snackbar