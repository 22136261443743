import React from "react";
import classes from "./SearchBoxDevice.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilterDevice,
  setFilterDevice,
} from "../../pages/Homepage/projectSlice";

function SearchBoxDevice() {
  const dispatch = useDispatch();
  const filterDevice = useSelector(selectFilterDevice);

  const setFilters = (event) => {
    dispatch(setFilterDevice(event.target.value));
  };

  return (
    <div className={classes.searchElementDevice} data-cy='searchElement'>
      <div className={classes.searchControlDevice} data-cy='searchControl'>
        <div className={classes.searchBoxDevice} data-cy='searchBox'>
          <input
            id='searchDetailsDevice'
            autoComplete='off'
            required
            data-cy='searchInput'
            value={filterDevice}
            onChange={setFilters}
          ></input>
          <label
            htmlFor='searchDetailsDevice'
            className={classes.searchLabel}
            data-cy='searchLabel'
          >
            {/* <span className={classes.searchContent} data-cy='searchContent'>
              Search...{" "}
            </span>{" "} */}
          </label>
        </div>
      </div>
      {/* <div className={classes.searchAction} data-cy='searchButton' onClick={() => {searchHandler()}}>
        {" "}
        <img src={search} alt="" />{" "}
      </div> */}
    </div>
  );
}

export default SearchBoxDevice;
